<template>
  <div class="image">
    <template v-if="!isArray && !Array.isArray(myUrl)">
      <el-image :src="myUrl" fit="contain" :preview-src-list="[myUrl]" :style="{ width: width, height: height }">
        <div slot="error" class="image-slot" :style="{ width: width, height: height }">
          <img
            src="https://nhub-oss.oss-cn-chengdu.aliyuncs.com/static/tabBar/logo_index_black.png"
            alt=""
          />
        </div>
      </el-image>
    </template>

    <div v-else>
      <template v-if="imageurl && imageurl.length > 0">
        <div class="imgs">
          <el-image
            v-for="(item, index) in imageurl"
            :key="index"
            fit="contain"
            :src="item"
            :style="{ width: width, height: height }"
            :preview-src-list="[item]"
          >
            <div slot="error" class="image-slot" :style="{ width: width, height: height }">
              <img
                src="https://nhub-oss.oss-cn-chengdu.aliyuncs.com/static/tabBar/logo_index_black.png"
                alt=""
              />
            </div>
          </el-image>
        </div>
      </template>

      <span v-else>——</span>
    </div>
  </div>
</template>

<script>
import { isArray, flattenDeep } from 'lodash-es';

export default {
  name: 'smart-image',
  props: {
    url: {
      type: [String, Array],
      require: true,
      default() {
        return [];
      },
    },
    width: {
      type: String,
      default: '100%',
      require: false,
    },
    height: {
      type: String,
      default: '50px',
      require: false,
    },
  },
  data() {
    return {
      imageurl: null,
      isArray: false,
      fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
    };
  },
  computed: {
    myUrl: function () {
      // let _url = this.$props.url;
      // if (_url) {
      //   _url = this.flattenDeepImage(_url);
      // }
      return this.$props.url;
    },
  },
  watch: {
    myUrl: function (val, old) {
      if (val instanceof Array) {
        this.isArray = true;
      } else {
        this.isArray = false;
      }
      this.imageurl = val;
    },
  },
  methods: {
    flattenDeep,
    flattenDeepImage(arr) {
      if (typeof arr == 'string') {
        return arr;
      }
      if (!isArray(arr)) return [];
      if (arr.length == 0) return [];
      let _arr = [];
      console.log(arr, 'arr');

      arr.forEach((v, i) => {
        if (typeof v == 'string') {
          if (this.isJSONStr(v)) {
            const _imgArr = JSON.parse(v);
            console.log(_imgArr);
            _arr = _arr.concat(_imgArr);
          } else {
            _arr.splice(i, 0, v);
          }
        }
        if (isArray(v)) {
          _arr = _arr.concat(v);
        }
      });
      return _arr;
    },
    isJSONStr(str) {
      if (typeof str == 'string') {
        try {
          let obj = JSON.parse(str);
          if (typeof obj == 'object') {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.image {
  display: flex;
  align-items: center;
}
.image-slot {
  text-align: center;
  object-fit: cover;
  img {
    width: 100%;
    height: 100%;
  }
}
.imgs {
  display: flex !important;
  .el-image {
    margin-right: 10px;
  }
}
</style>
