import { getAction, postAction } from './manage.js'

import vue from '../main.js'
// const ADMIN = vue.$store.state.user.userInfo.loginName
const ADMIN = '/admin'
const shopConfig = {
  //获取商户扫呗配置
  getSet: (params) => getAction(ADMIN + '/mall/sb-pay-setting/get-mall-info', params),
  //获取商户配置(新)
  getSetPay: (params) => postAction(ADMIN + '/mall/pay-setting/info', params),
  //商户扫呗配置
  getShopSet: (params) => getAction(ADMIN + '/mall/sb-pay-setting/setting-mall', params),
  // 修改扫呗配置
  editShopConfig: (params) => postAction(ADMIN + '/mall/manage/sb-pay-setting/setting', params),
  // 修改扫呗配置(新)
  editShopPayConfig: (params) => postAction(ADMIN + '/mall/pay-setting/save', params),
  //获取商城打印机配置
  getPrinter: (params) => getAction(ADMIN + '/mall/printer-setting/get-mall-row', params),
  //关于我们
  getAbout: (params) => getAction(ADMIN + '/mall/store/setting/extend/get-about-us', params),
  // 获取联系我们
  getContact: (params) => getAction(ADMIN + '/mall/store/setting/extend/get-contact-us', params),
  getDisclaimers: (params) => getAction('/admin/mall/store/setting/extend/get-disclaimers', params),
  setDisclaimers: (params) => postAction('/admin/mall/store/setting/extend/save-disclaimers', params),
  //保存关于我们
  saveAbout: (params) => postAction(ADMIN + '/mall/store/setting/extend/save-about-us', params),
  //保存联系我们
  saveConcat: (params) => postAction(ADMIN + '/mall/store/setting/extend/save-contact-us', params),
  //保存商户配置
  saveSetting: (params) => postAction(ADMIN + '/mall/tenant/save', params),
  // 获取用户协议
  getProtocol: (params) => getAction(ADMIN + '/mall/store/setting/extend/get-user-protocol', params),
  //保存用户协议
  saveprotocol: (params) => postAction(ADMIN + '/mall/store/setting/extend/save-user-protocol', params),
  // 获取隐私政策
  getPolicy: (params) => getAction(ADMIN + '/mall/store/setting/extend/get-privacy-policy', params),
  // 获取app隐私政策
  getAppPolicy: (params) => getAction(ADMIN + '/app/store/setting/extend/get-privacy-policy', params),
  //保存隐私政策
  savePolicy: (params) => postAction(ADMIN + '/mall/store/setting/extend/save-privacy-policy', params),
  //保存会员权益
  saveEquity: (params) => postAction(ADMIN + '/mall/store/setting/extend/save-member-benefits', params),
  // 获取会员权益
  getEquity: (params) => getAction(ADMIN + '/mall/store/setting/extend/get-member-benefits', params),
  // 获取充值规则
  getRecharge: (params) => getAction(ADMIN + '/mall/store/setting/extend/get-recharge-rules', params),
  //保存充值规则
  saveRecharge: (params) => postAction(ADMIN + '/mall/store/setting/extend/save-recharge-rules', params),
  // 获取SP能量
  getSp: (params) => getAction(ADMIN + '/mall/store/setting/extend/get-sp-energy', params),
  //保存SP能量
  saveSp: (params) => postAction(ADMIN + '/mall/store/setting/extend/save-sp-energy', params),
  // 获取SP能量
  getMember: (params) => getAction(ADMIN + '/mall/store/setting/extend/get-member-rules-instruction', params),
  //保存SP能量
  saveMember: (params) => postAction(ADMIN + '/mall/store/setting/extend/save-member-rules-instruction', params),
}

export {
  shopConfig
}
